import React, { Fragment } from 'react'
import { Link } from 'gatsby'
import facebook from '../../assets/icons/mobile/facebook-footer.svg'
import instagram from '../../assets/icons/mobile/instagram-footer.svg'
import youtube from '../../assets/icons/mobile/youtube-footer.svg'

const Footer = () => (
  <Fragment>
    <div className='d-none d-lg-block'>
      <footer className="d-flex" style={{ backgroundColor: '#4a4a4a', height: '70px' }}>
        <div className="container d-flex align-self-center">
          <p className="m-0 text-white pt-1">&copy; {(new Date().getFullYear())} DrogaLeste | <Link to="/lgpd/" className="text-white">Política de Privacidade | </Link>Siga-nos nas redes sociais:</p>
          <a href='https://pt-br.facebook.com/drleste/' target="_blank" rel="noopener noreferrer"><img src={facebook} width='25px' className='ml-2'></img></a>
          <a href='https://www.instagram.com/drogaleste/' target="_blank" rel="noopener noreferrer"><img src={instagram} width='25px' className='ml-2'></img></a>
          <a href='https://www.youtube.com/@drogaleste3564/videos' target="_blank" rel="noopener noreferrer"><img src={youtube} width='25px' className='ml-2'></img></a>
          <p className="m-0 ml-auto pt-1"> <a className="clouddog" href="https://www.clouddog.com.br" title="Ir para a o site da CloudDog">Desenvolvido por CloudDog</a></p>
        </div>
      </footer>
    </div>
    <div className='d-lg-none d-block'>
      <footer className="row justify-content-center m-0" style={{ backgroundColor: '#4a4a4a', height: '120px' }}>
        <div className='col-12 d-flex justify-content-center align-self-center pt-2'>
          <p className="m-0 text-white">&copy; {(new Date().getFullYear())} DrogaLeste | <Link to="/lgpd/" className="text-white">Política de Privacidade </Link></p>
        </div>
        <div className='col-12 d-flex justify-content-center align-self-center'>
          <p className='m-0 text-white pt-1'>Siga-nos nas redes sociais:</p>
          <a href='https://pt-br.facebook.com/drleste/' target="_blank" rel="noopener noreferrer"><img src={facebook} width='25px' className='ml-2'></img></a>
          <a href='https://www.instagram.com/drogaleste/' target="_blank" rel="noopener noreferrer"><img src={instagram} width='25px' className='ml-2'></img></a>
          <a href='https://www.youtube.com/@drogaleste3564/videos' target="_blank" rel="noopener noreferrer"><img src={youtube} width='25px' className='ml-2'></img></a>
        </div>
        <div className='col-12 d-flex justify-content-center align-self-center'>
          <p className="m-0"> <a className="clouddog" href="https://www.clouddog.com.br" title="Ir para a o site da CloudDog">Desenvolvido por CloudDog</a></p>
        </div>
      </footer>
    </div>

  </Fragment>
)

export default Footer
