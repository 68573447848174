module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-cloudofertas/gatsby-browser.js'),
      options: {"plugins":[],"env":{"dev":{"siteId":"42100970-3f88-4515-b060-8c15c7bb3133","accessToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6Ijk5MDRkZjM2LWQzNjctNGViYy04ZjQxLTJlMjZmMTUxNDMyNyIsInVzZXIiOiJiYTk2Mzk5Yi1mYzdkLTQyMDktOTEzMC0xMjJlNmM2MTBhMWMiLCJzaXRlIjoiNDIxMDA5NzAtM2Y4OC00NTE1LWIwNjAtOGMxNWM3YmIzMTMzIiwiaWF0IjoxNjUxMTY4MDc1fQ.TcZTDwrO6yu4a3O0HbVwbcDsm-cBC5x3QA60BPUcxHo"},"prd":{"siteId":"42100970-3f88-4515-b060-8c15c7bb3133","accessToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6Ijk5MDRkZjM2LWQzNjctNGViYy04ZjQxLTJlMjZmMTUxNDMyNyIsInVzZXIiOiJiYTk2Mzk5Yi1mYzdkLTQyMDktOTEzMC0xMjJlNmM2MTBhMWMiLCJzaXRlIjoiNDIxMDA5NzAtM2Y4OC00NTE1LWIwNjAtOGMxNWM3YmIzMTMzIiwiaWF0IjoxNjUxMTY4MDc1fQ.TcZTDwrO6yu4a3O0HbVwbcDsm-cBC5x3QA60BPUcxHo"}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/codebuild/output/src1502104477/src/src/components/Layout/index.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"DrogaLeste","short_name":"drogaleste","start_url":"/","background_color":"#e6e4e4","theme_color":"#1a3797","display":"minimal-ui","icon":"src/assets/images/favicon/favicon-32x32.png","cache_busting_mode":"none","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
