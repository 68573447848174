import React, { Fragment, useState, useEffect } from 'react'
import { Link } from 'gatsby'

import LogoDrogalesteDesktop from '../../assets/images/desktop/logo-drogaleste.svg'
import LogoDrogalesteMobile from '../../assets/images/mobile/logo-drogaleste.svg'
import './header.css'

const Sidebar = () => {
  const [openSideBar, setOpenSidebar] = useState(false)
  const [displayDropDown, setDisplayDropDown] = useState(false)
  const [displayDropDownBlog, setDisplayDropDownBlog] = useState(false)
  const [displayNavMobile, setDisplayNavMobile] = useState(false)
  const [displayNavMobileBlog, setDisplayNavMobileBlog] = useState(false)
  const [currentStore, setCurrentStore] = useState(null)

  useEffect(() => {
    const current = JSON.parse(window.localStorage.getItem('currentStore'))
    setCurrentStore(current)
  }, [])

  // const lojasInauguracao = useStaticQuery(
  //   graphql` {
  //     allCloudOfertasInauguracaoLoja {
  //       nodes {
  //         flg_link
  //         slug
  //       }
  //     }
  //   }

  //   `
  // )

  // const linkLojaInauguracao = lojasInauguracao.allCloudOfertasInauguracaoLoja.nodes.filter((data) => {
  //   return data.flg_link === true
  // })

  return (
    <Fragment>
      <nav>
        {/* Desktop */}
        <div id="navbar-desktop" className="navbar-expand-sm header-display">
          <div className="d-flex flex-column">
            <div className="theme--primary d-flex flex-column justify-content-center" style={{ zIndex: '1' }}>
              <div className="container">
                <div className="row">
                  <div className="col">
                    <div className="sidebar-header">
                      <Link to="/"><img className="sidebar-logo" src={LogoDrogalesteDesktop} title="Logotipo da DrogaLeste" alt="Logotipo da Drogaleste" /></Link>
                    </div>
                  </div>
                  <div className="col item-store d-flex flex-column justify-content-center align-items-center">
                    <Link to="/lojas/" className="btn theme-secundary"><span className="ml-3">NOSSAS LOJAS</span></Link>
                  </div>
                  {/* <div className="col item-black-leste d-flex flex-column justify-content-center align-items-center pr-4">
                    <Link to="/black-leste/" className="btn text-white"><span className="bold ml-3">BLACK</span><span className='bold bluefont'>LESTE</span></Link>
                  </div> */}
                  <div className="col item-offers-week d-flex flex-column justify-content-center align-items-center">
                    <Link to="/ofertas/" className="btn theme--secundary text-white"><span className="ml-3">OFERTAS</span></Link>
                  </div>
                  <div className="col televendas-button d-flex flex-column justify-content-center align-items-center">
                    <a href='https://api.whatsapp.com/send?=pt_BR&phone=5511947796762' className="btn btn-custom text-white"><span className="ml-3">TELEVENDAS</span></a>
                  </div>
                  {/* <div className="col item-inauguracaoLoja d-flex flex-column justify-content-center align-items-center">
                    <Link to={`/inauguracao-loja/${linkLojaInauguracao[0].slug}`} className="btn theme--secundary text-white"><span className="ml-3 text-uppercase">Lojas Inauguração</span></Link>
                  </div> */}
                  <div className="store-name-desktop col d-flex flex-column align-items-end">
                    {currentStore !== null &&
                    <></>
                    }
                    {currentStore == null &&
                      <>
                        {/* <p className='my-auto text-white'>
                          <span className="d-none" id='spanStore'>Olá! Você está na </span>
                          <a href="/lojas/" id="currentMarket" title="Escolha uma loja" className="text-white store-name mr-3">Escolha uma loja</a>
                        </p> */}
                      </>
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white d-flex flex-column justify-content-center">
              <div className="container">
                <div className="row">
                  <ul className="navbar-nav justify-content-between w-100">
                    <li>
                      <Link to="/" className="nav-link">HOME</Link>
                    </li>
                    <li>
                      <Link to="/quem-somos/" className="nav-link">QUEM SOMOS</Link>
                    </li>
                    <li>
                      <Link to="/lojas/" className="nav-link">ENCONTRE UMA LOJA</Link>
                    </li>
                    <li>
                      <Link to="/ofertas/" className="nav-link">OFERTAS</Link>
                    </li>
                    <li className="dropdown btn-group">
                      <a href="#" id="dropdownConvenio" className="dropdown-toggle nav-link" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"
                        onClick={() => setDisplayDropDown(!displayDropDown)}>CONVÊNIOS</a>
                      <div className={displayDropDown === true ? 'dropdown-menu show' : 'dropdown-menu'} aria-labelledby="dropdownConvenio">
                        <Link to="/convenio-empresarial/" className="dropdown-item">CONVÊNIO EMPRESARIAL</Link>
                        <Link to="/convenios-parcerias/" className="dropdown-item">DESCONTOS E PARCERIAS</Link>
                      </div>
                    </li>
                    <li>
                      <Link to="/trabalhe-conosco/" className="nav-link">TRABALHE CONOSCO</Link>
                    </li>
                    <li className="dropdown btn-group">
                      <a href="/blog/" className="dropdown-toggle nav-link" data-toggle="dropdown" role="button"
                        onClick={() => setDisplayDropDownBlog(!displayDropDownBlog)} aria-haspopup="true" aria-expanded="false">
                        BLOG
                      </a>
                      <div className={displayDropDownBlog === true ? 'dropdown-menu show my-dropdown' : 'dropdown-menu'}>
                        <Link to="/blog/pais-e-filhos/" className="dropdown-item">PAIS E FILHOS</Link>
                        <Link to="/blog/beleza/" className="dropdown-item">MUNDO DA BELEZA</Link>
                        <Link to="/blog/senior/" className="dropdown-item">SÊNIOR</Link>
                      </div>
                    </li>
                    <li>
                      <Link to="/contato/" className="nav-link">CONTATO</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Mobile Sidebar */}
        <div id="fixed-mobile" className="wrapper theme--primary d-none">
          <div id="sidebar" className={openSideBar ? 'active' : ''}>
            <div id="dismiss" className="position-absolute theme-secundary" onClick={() => setOpenSidebar(false)}><span className="sr-only">Fechar menu</span></div>
            <div className="sidebar-header d-flex flex-column justify-content-center align-items-center bg-white">
              <Link to="/"><img src={LogoDrogalesteMobile} title="Logotipo da Drogaleste" alt="Logotipo da Drogaleste" /></Link>
            </div>
            <ul className="list-unstyled CTAs pt-4 pb-2 px-auto" id='sidebartelevendas'>
              <li>
                <Link to="/lojas/" className="d-block text-center m-auto theme-border-2 theme--primary btn-store-us text-decoration-none">NOSSAS LOJAS</Link>
              </li>
              <li className="mt-3">
                <Link to="/ofertas/" className="d-block text-center m-auto theme-border-2 theme--secundary btn-offers-week">OFERTAS RELÂMPAGO</Link>
              </li>
              <li className='mt-3'>
                <a href='https://api.whatsapp.com/send?=pt_BR&phone=5511947796762' className="d-block text-center m-auto theme-border-2 televendas-button text-decoration-none">TELEVENDAS</a>
              </li>
            </ul>
            <hr className="theme-border-2" />
            <ul className="position-relative mobile-links my-0 list-unstyled components text-center">
              <li>
                <Link to="/">HOME</Link>
              </li>
              <li>
                <Link to="/quem-somos/">QUEM SOMOS</Link>
              </li>
              <li className="mobile-dropdown">
                <a href="#" id="agreement-dropdown-button" onClick={() => setDisplayNavMobile(!displayNavMobile)}>CONVÊNIOS<span className="dropdown-arrow"></span></a>
                <ul id="agreement-dropdown" className={displayNavMobile === true ? 'active' : ''}>
                  <li><Link to="/convenio-empresarial/">CONVÊNIO EMPRESARIAL</Link></li>
                  <li><Link to="/convenios-parcerias/">DESCONTOS <br />E PARCERIAS</Link></li>
                </ul>
              </li>
              <li>
                <Link to="/trabalhe-conosco/">TRABALHE CONOSCO</Link>
              </li>
              <li className="mobile-dropdown">
                <a href="#" id="blog-dropdown-button" onClick={() => setDisplayNavMobileBlog(!displayNavMobileBlog)}>BLOG<span className="dropdown-arrow"></span></a>
                <ul id="blog-dropdown" className={displayNavMobileBlog === true ? 'active' : ''}>
                  <li><Link to="/blog/pais-e-filhos/">PAIS E FILHOS</Link></li>
                  <li><Link to="/blog/beleza/">MUNDO DA BELEZA</Link></li>
                  <li><Link to="/blog/senior/">SÊNIOR</Link></li>
                </ul>
              </li>
              <li>
                <Link to="/contato/">CONTATO</Link>
              </li>
            </ul>
            <ul id="social-media" className="list-inline text-center">
              <li className="list-inline-item">
                <a href="https://www.instagram.com/drogaleste" title="Instagram DrogaLeste"><span className="sr-only">Instagram</span></a>
              </li>
              <li className="list-inline-item">
                <a href="https://twitter.com/droga_leste" title="Twitter DrogaLeste"><span className="sr-only">Twitter</span></a>
              </li>
              <li className="list-inline-item">
                <a href="https://www.facebook.com/rededleste" title="Facebook DrogaLeste"><span className="sr-only">Facebook</span></a>
              </li>
            </ul>
          </div>
          <div id="mobile-menu" className="container d-flex flex-column justify-content-center">
            <div className="row">
              <div className="col-2 p-md-0 d-flex flex-column justify-content-center">
                <button type="button" className="hamburguer position-relative d-flex flex-column justify-content-center" onClick={() => setOpenSidebar(true)}>
                  <div className="position-relative line theme-border-2"></div>
                  <div className="position-relative line theme-border-2"></div>
                  <div className="position-relative line theme-border-2"></div>
                  <span className="sr-only">Abrir menu</span>
                </button>
              </div>
              <div className="col-5 col-md-8 p-md-0 d-flex flex-column  align-items-md-center p-0">
                <Link to="/" className="d-flex flex-column justify-content-center"><img src={LogoDrogalesteDesktop} title="Logotipo da Drogaleste" alt="Logotipo da Drogaleste" /></Link>
              </div>
              {/* <div className="blackleste-mobile d-flex flex-column p-0">
                <Link to="/black-leste/" className="btn text-white"><div className='pl-2'><span className="bold font-blackleste">BLACK</span><br></br><span className='bold bluefont font-blackleste'>LESTE</span></div></Link>
              </div> */}
            </div>
          </div>
          <div onClick={() => setOpenSidebar(false)} className={openSideBar ? 'overlay active' : ''}></div>
        </div>
      </nav>
    </Fragment>
  )
}

export default Sidebar
