exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-black-leste-js": () => import("./../../../src/pages/black-leste.js" /* webpackChunkName: "component---src-pages-black-leste-js" */),
  "component---src-pages-contato-js": () => import("./../../../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-convenio-empresarial-js": () => import("./../../../src/pages/convenio-empresarial.js" /* webpackChunkName: "component---src-pages-convenio-empresarial-js" */),
  "component---src-pages-convenios-parcerias-js": () => import("./../../../src/pages/convenios-parcerias.js" /* webpackChunkName: "component---src-pages-convenios-parcerias-js" */),
  "component---src-pages-cupom-js": () => import("./../../../src/pages/cupom.js" /* webpackChunkName: "component---src-pages-cupom-js" */),
  "component---src-pages-favoritos-js": () => import("./../../../src/pages/favoritos.js" /* webpackChunkName: "component---src-pages-favoritos-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lgpd-js": () => import("./../../../src/pages/lgpd.js" /* webpackChunkName: "component---src-pages-lgpd-js" */),
  "component---src-pages-lojas-js": () => import("./../../../src/pages/lojas.js" /* webpackChunkName: "component---src-pages-lojas-js" */),
  "component---src-pages-ofertas-do-dia-js": () => import("./../../../src/pages/ofertas-do-dia.js" /* webpackChunkName: "component---src-pages-ofertas-do-dia-js" */),
  "component---src-pages-ofertas-js": () => import("./../../../src/pages/ofertas.js" /* webpackChunkName: "component---src-pages-ofertas-js" */),
  "component---src-pages-quem-somos-js": () => import("./../../../src/pages/quem-somos.js" /* webpackChunkName: "component---src-pages-quem-somos-js" */),
  "component---src-pages-trabalhe-conosco-js": () => import("./../../../src/pages/trabalhe-conosco.js" /* webpackChunkName: "component---src-pages-trabalhe-conosco-js" */),
  "component---src-templates-blog-beleza-template-js": () => import("./../../../src/templates/blog-beleza-template.js" /* webpackChunkName: "component---src-templates-blog-beleza-template-js" */),
  "component---src-templates-blog-list-template-js": () => import("./../../../src/templates/blog-list-template.js" /* webpackChunkName: "component---src-templates-blog-list-template-js" */),
  "component---src-templates-blog-pais-template-js": () => import("./../../../src/templates/blog-pais-template.js" /* webpackChunkName: "component---src-templates-blog-pais-template-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-blog-senior-template-js": () => import("./../../../src/templates/blog-senior-template.js" /* webpackChunkName: "component---src-templates-blog-senior-template-js" */),
  "component---src-templates-contato-detalhe-js": () => import("./../../../src/templates/contato-detalhe.js" /* webpackChunkName: "component---src-templates-contato-detalhe-js" */),
  "component---src-templates-cupom-inauguracao-loja-js": () => import("./../../../src/templates/cupom-inauguracao-loja.js" /* webpackChunkName: "component---src-templates-cupom-inauguracao-loja-js" */),
  "component---src-templates-inauguracao-loja-post-js": () => import("./../../../src/templates/inauguracao-loja-post.js" /* webpackChunkName: "component---src-templates-inauguracao-loja-post-js" */),
  "component---src-templates-ofertas-do-dia-template-js": () => import("./../../../src/templates/ofertas-do-dia-template.js" /* webpackChunkName: "component---src-templates-ofertas-do-dia-template-js" */)
}

