const TagManager = require('react-gtm-module')

exports.onClientEntry = () => {
  if (typeof document !== 'undefined') {
    const TagManagerArgs = {
      gtmId: 'GTM-523T6G8'
    }
    TagManager.initialize(TagManagerArgs)
  }
}
